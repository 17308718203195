import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom"; // For navigation and accessing the URL

import { UserContext } from "../context/UserContext";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  CircularProgress,
  Box,
  FormControl,
  Input,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemButton,
  Divider,
  IconButton,
  Grid,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  Card,
  Paper,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DescriptionIcon from "@mui/icons-material/Description";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder"; // Import CreateNewFolderIcon
import {
  AttachFile,
  Download,
  Folder,
  PersonAdd,
  Search,
  Backspace,
} from "@mui/icons-material";
import firebase from "../firebase";
// import { auth, sendPasswordResetEmail, signOut } from "../firebase";
import auth from "../firebase";
import sendPasswordResetEmail from "../firebase";
import signOut from "../firebase";
import { debounce } from "lodash"; // Import debounce function
import ActionMenuButton from "./ActionMenuButton";
import moment from "moment";
import { apiLink } from "../common";
import { useDropzone } from "react-dropzone";

function Dashboard() {
  const [manageInvestor, setManageInvestor] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [currentFolderId, setCurrentFolderId] = useState(0);
  const [genericFolderId, setGenericFolderId] = useState(false);
  const [downloadingFileID, setDownloadingFileID] = useState(-1);
  const [breadCrumbs, setbreadCrumbs] = useState([]);
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [assignUserLoading, setAssignUserLoading] = useState(false);
  // const [selectedFile, setSelectedFile] = useState([]);
  const [newFolderEmail, setNewFolderEmail] = useState(null);
  const [newFolderPhone, setNewFolderPhone] = useState(null);
  const [loadingFolders, setLoadingFolders] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showingOptions, setShowingOptions] = useState(null);
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [genericFolderData, setGenericFolderData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate(); //
  const location = useLocation(); // Get the current location

  const debouncedSearch = debounce(() => {
    setDebouncedSearchText(searchText);
  }, 1000);

  useEffect(() => {
    debouncedSearch();
  }, [searchText, debouncedSearch]);

  const handleClearSearch = () => {
    setSearchText("");
    setDebouncedSearchText("");
  };

  const getFiles = async () => {
    if (!user) return;
    try {
      const response = await axios.get(
        `${apiLink}/api/files/folder/${currentFolderId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setFiles(response.data);
    } catch (error) {
      console.log("get files error");
      if (error.response.status !== 404) setError(error.message);
    }
  };

  const getGenericFolders = async (showLoading) => {
    if (!user) return;
    try {
      if (showLoading) setLoadingFolders(true);
      const response = await axios.get(
        apiLink + "/api/folders/admin-folder/" + user?.id,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          // params: {
          //   searchText: debouncedSearchText, // Use debouncedSearchText in the request
          // },
        }
      );

      // console.log(response?.data,"folders");
      await setGenericFolderData(response?.data?.folders);
      // if (user?.role !== "A")
      //   setCurrentFolder(response.data[0].id, response.data[0].name);
      setLoadingFolders(false);
    } catch (error) {
      // if (error.response.status == 401) handleLogout();
      setLoadingFolders(false);
      setError(error.message);
    }
  };

  const getGenericFoldersForUser = async (showLoading) => {
    if (!user) return;
    try {
      if (showLoading) setLoadingFolders(true);
      const response = await axios.get(
        apiLink + "/api/folders/client-folder/" + user?.id,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          // params: {
          //   searchText: debouncedSearchText, // Use debouncedSearchText in the request
          // },
        }
      );

      console.log(response?.data, "folders");
      console.log(response?.data, "response?.data");

      await setGenericFolderData(response?.data?.folders);
      // if (user?.role !== "A")
      //   setCurrentFolder(response.data[0].id, response.data[0].name);
      setLoadingFolders(false);
    } catch (error) {
      // if (error.response.status == 401) handleLogout();
      setLoadingFolders(false);
      setError(error.message);
    }
  };

  const getFolders = async (showLoading) => {
    if (!user) return;
    try {
      if (showLoading) setLoadingFolders(true);
      const response = await axios.get(apiLink + "/api/folders", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        params: {
          searchText: debouncedSearchText, // Use debouncedSearchText in the request
        },
      });

      // console.log(response?.data?.clientId, "folders");
      await setFolders(response.data);
      const filterData = response.data.filter(
        (data) => Number(data.clientId) === user.id
      );

      if (user?.role !== "A")
        setCurrentFolder(filterData[0].id, filterData[0].name);
      setLoadingFolders(false);
    } catch (error) {
      // if (error.response.status == 401) handleLogout();
      setLoadingFolders(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    if (user?.role === "A") {
      getGenericFolders();
    } else {
      getGenericFoldersForUser();
    }
  }, [user]);

  useEffect(() => {
    console.log("user");
    getFolders();
    console.log(folders, "fodlderdASHBOARD");
  }, [user, debouncedSearchText]);

  useEffect(() => {
    if (currentFolderId) {
      getFiles();
    }
  }, [currentFolderId, user]);
  // console.log(genericFolderData, "setGenericFolderData");

  const [dragActive, setDragActive] = useState(false); // Example of another useState

  const [openAddFileDialog, setOpenAddFileDialog] = useState(false);
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);

  const handleAddFileDialogOpen = () => {
    setOpenAddFileDialog(true);
  };

  useEffect(() => {
    console.log(selectedFiles, "emptyselectedfile");
  }, [selectedFiles]);

  const handleAddFileDialogClose = () => {
    setSelectedFiles([]);
    setOpenAddFileDialog(false);
  };

  const handleCreateFolderDialogClose = () => {
    setOpenCreateFolderDialog(false);
    setGenericFolderId(false);
  };

  const [newFolderName, setNewFolderName] = useState("");
  const [creatingFolder, setCreatingFolder] = useState(false);

  const handleFolderNameChange = (e) => {
    setNewFolderName(e.target.value);
  };

  const handleNewFolderEmailChange = (e) => {
    console.log(e.target.value, "aaaaaaaaaaaa");

    setNewFolderEmail(e.target.value);
  };
  const handleNewFolderPhoneChange = (e) => {
    setNewFolderPhone(e.target.value);
  };

  const setCurrentFolder = (id, name) => {
    let tempBreadcrumbs = breadCrumbs;
    tempBreadcrumbs.push({ id, name });
    setCurrentFolderId(id);

    navigate(`/folder/${id}`);
  };

  const goBack = () => {
    breadCrumbs.pop();
    setbreadCrumbs(breadCrumbs);
    if (breadCrumbs.length > 0) {
      setCurrentFolderId(breadCrumbs[breadCrumbs.length - 1].id);
      navigate(`/folder/${breadCrumbs[breadCrumbs.length - 1].id}`);
    } else {
      setCurrentFolderId("");
      navigate(`/`);
    }
  };

  useEffect(() => {
    const folderId = location.pathname.split("/folder/")[1] || "";
    if (folderId) {
      setCurrentFolderId(folderId); // Set the folder ID from the URL
    } else {
      setCurrentFolderId("");
    }
    console.log(location.pathname, "location.pathname");
    console.log(location.hash, "location.hash");
  }, [location.pathname]);

  //check if browser back button is clicked
  window.onpopstate = function (e) {
    e.preventDefault();
    if (!currentFolderId) {
      let back = window.confirm("Are you sure you want to go to login page?");
      if (back) {
        goBack();
      }
    } else goBack();
  };

  function isPhoneNumber(input) {
    // Define the regular expression for the phone number pattern
    const phoneNumberPattern = /^(\+?1\s?)?(\d{3}[-\s]?)?\d{3}[-\s]?\d{4}$/;

    if (!phoneNumberPattern.test(input)) {
      return false;
    }

    const digitCount = (input.match(/\d/g) || []).length;
    return digitCount >= 10;
  }

  const createFolder = async () => {
    try {
      if (!currentFolderId) {
        if (!genericFolderId) {
          // Validate email format
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(newFolderEmail)) {
            setError("Invalid email format");
            return;
          }

          if (!isPhoneNumber(newFolderPhone)) {
            setError("Invalid phone number");
            return;
          }
        }
      }
      setCreatingFolder(true);
      // const submit={
      //       name: newFolderName,
      //       parentId: currentFolderId,
      //       email: newFolderEmail,
      //       phone: newFolderPhone,
      //       folder_type: genericFolderId ? "1" : "0",
      //     }

      const response = await axios.post(
        apiLink + "/api/folders",
        {
          name: newFolderName,
          parentId: genericFolderId ? "0" : currentFolderId,
          email: newFolderEmail,
          phone: newFolderPhone,
          folder_type: genericFolderId ? "1" : "0",
          // adminId: user?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log(newFolderEmail, "newFolderEmail");

      if (!currentFolderId) {
        if (genericFolderId) {
          if (genericFolderData?.length) {
            setGenericFolderData([...genericFolderData, response.data]);
          } else {
            setGenericFolderData([response.data]);
          }
        } else {
          setFolders([...folders, response.data]);
        }
      }
      setNewFolderName("");
      setNewFolderEmail(null);
      setNewFolderPhone(null);
      setOpenAddFileDialog(null);
      setOpenCreateFolderDialog(null);
      setCreatingFolder(false);
      setOpenAddFileDialog(null);
      setOpenCreateFolderDialog(null);
      getFiles();
      setGenericFolderId("");
      getFolders(false);
      setOpenCreateFolderDialog(false);
      if (newFolderEmail) {
        firebase.auth().sendPasswordResetEmail(newFolderEmail);
      }
      // if (!currentFolderId) setFolders([...folders, response.data]);
      // if (newFolderEmail)

      // firebase.auth().sendPasswordResetEmail(newFolderEmail);
      // if (newFolderEmail) sendPasswordResetEmail(auth, newFolderEmail);
      // firebase.auth().sendPasswordResetEmail(newFolderEmail);
    } catch (error) {
      console.error(error);
      console.log(error?.request?.response);
      if (error?.request?.response) alert(error.request.response);
      setCreatingFolder(false);
    }
  };

  const handleAssignUser = async ({ folder_id, user_id }) => {
    setAssignUserLoading(true);
    const response = await axios.post(
      apiLink + "/api/folders/assign-folder",
      {
        userId: user_id,
        folderId: folder_id,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    console.log(response.data, "rrrrrrrrrrrrrrrrrrrr");

    if (response) {
      setAssignUserLoading(false);
    }

    // if (!currentFolderId) setFolders([...folders, response.data]);
  };

  const rename = async ({
    id,
    type = "folders",
    oldName,
    newName,
    newEmail,
    newPhone,
    generic,
  }) => {
    try {
      let indexToChange = -1;

      if (generic === "true") {
        indexToChange = genericFolderData.findIndex((file) => file.id === id);

        if (indexToChange !== -1) {
          genericFolderData[indexToChange].name = newName;
          setGenericFolderData([...genericFolderData]);
        }
      } else if (newEmail) {
        indexToChange = folders.findIndex((file) => file.id == id);

        if (indexToChange !== -1) {
          folders[indexToChange].name = newName;
          setFolders([...folders]);
        }
      } else {
        if (type == "files") {
          const lastDotIndex = oldName.lastIndexOf(".");
          const fileExtension = oldName.substring(lastDotIndex + 1);
          newName = `${newName}.${fileExtension}`;
        }
        indexToChange = files.findIndex((file) => file.id == id);
        if (indexToChange !== -1) {
          files[indexToChange].name = newName;
          setFiles([...files]);
        }
      }

      const response = await axios.put(
        `${apiLink}/api/${type}`,
        {
          name: newName,
          email: newEmail,
          phone: newPhone,
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      // if (type == 'folders') getFolders();
      // else getFiles();
    } catch (error) {
      console.error(error);
      if (error?.request?.response) alert(error.request.response);
    }
  };

  const deleteItem = async ({ id, email, type = "folders", generic }) => {
    try {
      let indexToChange = -1;
      if (generic === "true") {
        indexToChange = genericFolderData.findIndex((file) => file.id == id);
        if (indexToChange !== -1)
          setGenericFolderData([
            ...genericFolderData.slice(0, indexToChange),
            ...genericFolderData.slice(indexToChange + 1),
          ]);
      } else if (email) {
        indexToChange = folders.findIndex((file) => file.id == id);
        if (indexToChange !== -1)
          setFolders([
            ...folders.slice(0, indexToChange),
            ...folders.slice(indexToChange + 1),
          ]);
      } else {
        indexToChange = files.findIndex((file) => file.id == id);
        if (indexToChange !== -1)
          setFiles([
            ...files.slice(0, indexToChange),
            ...files.slice(indexToChange + 1),
          ]);
      }

      const response = await axios.delete(`${apiLink}/api/${type}/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      // if (type == 'folders') getFolders();
      // else getFiles();
    } catch (error) {
      console.error(error);
      if (error?.request?.response) alert(error.request.response);
    }
  };
  const downloadFile = async (fileId, name) => {
    try {
      setDownloadingFileID(fileId);
      const response = await axios.get(
        `${apiLink}/api/files/download/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          responseType: "blob", // Set response type to blob
        }
      );

      const filename = name;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadingFileID(-1);
    } catch (error) {
      setDownloadingFileID(-1);
      console.error(error);
    }
  };
  const handleLogout = async () => {
    await firebase.auth().signOut();
    await signOut(auth);
    await firebase.auth().signOut();
    localStorage.clear();
    setUser(null);
    // window.location.reload();
  };

  const generateRandomColor = () => {
    let colors = [
      "#005a31", // Dark Green
      "#2F4F4F", // Dark Slate Gray
      "#6A5ACD", // Slate Blue
      "#8B008B", // Dark Magenta
      "#800000", // Maroon
      "#556B2F", // Dark Olive Green
      "#483D8B", // Dark Slate Blue
      "#2E8B57", // Sea Green
      "#3D5A80", // Prussian Blue
      "#696969", // Dim Gray
      "#800020", // Burgundy
      "#FF4500", // Orange Red
      "#7B68EE", // Medium Slate Blue
      "#4682B4", // Steel Blue
      "#008B8B", // Dark Cyan
      "#B22222", // Fire Brick
    ];
    const randomColorKey = colors[Math.floor(Math.random() * colors.length)];
    return randomColorKey;
  };

  const handleDrop = (acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
    console.log(acceptedFiles, "acceptedFiles");
  };
  // console.log(selectedFile,"selectedFile");

  const onUpload = useCallback(async () => {
    if (selectedFiles.length === 0) return; // If no files are selected, do nothing

    try {
      setUploading(true);

      const formData = new FormData();
      const folderStructure = {};

      selectedFiles.forEach((file) => {
        const fullPath = file.path; // This includes the folder path and file name

        // Add file and its path to formData
        formData.append("files", file);
        formData.append("paths", fullPath);

        // Extract the folder path
        const parts = fullPath.split("/");
        parts.pop(); // Remove the file name
        const folderPath = parts.join("/");

        // Store the folder path in the structure
        if (folderPath) {
          if (!folderStructure[folderPath]) {
            folderStructure[folderPath] = true;
          }
        }
      });

      // Send the folder structure along with the files
      formData.append("folderStructure", JSON.stringify(folderStructure));

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      };

      await axios.post(
        `${apiLink}/api/files/upload/${currentFolderId}`,
        formData,
        config
      );

      const response = await axios.get(
        `${apiLink}/api/files/folder/${currentFolderId}`,
        { headers: { Authorization: `Bearer ${user.token}` } }
      );

      setFiles(response.data);
      setSelectedFiles([]);
      setOpenAddFileDialog(null);
      setOpenCreateFolderDialog(null);
      setUploading(false);
      handleAddFileDialogClose();
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false);
    }
  }, [selectedFiles, apiLink, currentFolderId, handleAddFileDialogClose]);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: handleDrop,
    noClick: true, // Disables the click to open file dialog
    noKeyboard: true, // Disables the keyboard behavior
    webkitdirectory: true, // Enable folder uploads
    directory: true,
    multiple: true, // Allow multiple files and folders
    onDragEnter: () => setDragActive(true),
    onDragLeave: () => setDragActive(false),
    accept: "image/*,application/pdf",
  });
  // useEffect(()=>{
  const existFile = [...genericFolderData, ...files];
  // },[genericFolderData,files])
  console.log(genericFolderData, "genericFolderData");

  return (
    <Container
      maxWidth="md"
      fullWidth
      sx={{
        "&.MuiContainer-root": { paddingLeft: 0 },
        root: { paddingLeft: 0 },
        paddingLeft: { xs: 0 },
        paddingRight: { xs: 0 },
      }}
    >
      {loadingFolders && !showSearch ? (
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>
            <Typography>Loading Folders & Files ...</Typography>
          </Grid>
        </Grid>
      ) : !currentFolderId ? (
        <>
          {/* <Typography variant='h6' component='h2' align='left' gutterBottom>
            Folders
          </Typography> */}
          {user?.role === "A" && (
            <>
              {/* Search Bar */}
              <Grid container justifyContent={"start"}>
                <Grid item xs={12} marginLeft={2}>
                  {showSearch ? (
                    <Grid
                      container
                      alignItems={"end"}
                      justifyContent={"end"}
                    ></Grid>
                  ) : (
                    <Grid container justifyContent={"end"}>
                      <Grid item>
                        {!manageInvestor ? (
                          <Button
                            onClick={() => setManageInvestor(true)}
                            style={{ color: "white" }}
                            variant="contained"
                            startIcon={<PersonAdd />}
                          >
                            Manage Investors
                          </Button>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {/* <ListItemButton
                color='primary'
                button
                onClick={() => setOpenCreateFolderDialog(true)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <ListItemIcon color='primary'>
                  <PersonAdd color='primary' />
                </ListItemIcon>
                <ListItemText color='primary' primary='Create Client Folder' />
              </ListItemButton> */}
              {/* <Divider variant='inset' /> */}
            </>
          )}

          {manageInvestor ? (
            <>
              <Grid item xs={12}>
                {showSearch ? (
                  <Grid container alignItems={"end"} justifyContent={"end"}>
                    <Grid item xs>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="search-text">Search</InputLabel>
                        <Input
                          autoFocus={true}
                          id="search-text"
                          type="text"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          startAdornment={<Search />}
                          endAdornment={
                            loadingFolders ? (
                              <CircularProgress />
                            ) : (
                              searchText && (
                                <IconButton
                                  onClick={handleClearSearch}
                                  edge="end"
                                >
                                  <CloseIcon />
                                </IconButton>
                              )
                            )
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="text"
                        onClick={() => {
                          setShowSearch(false);
                          handleClearSearch();
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    justifyContent={"space-between"}
                    marginTop={"5px"}
                  >
                    <Grid>
                      <Button
                        onClick={() => setManageInvestor(false)}
                        style={{ color: "white" }}
                        variant="contained"
                        startIcon={<Backspace />}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        onClick={() => setShowSearch(true)}
                        variant="text"
                        color="primary"
                        startIcon={<Search />}
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => setOpenCreateFolderDialog(true)}
                        style={{ color: "white" }}
                        variant="contained"
                        startIcon={<PersonAdd />}
                      >
                        Add Investor
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Paper variant="outlined" style={{ marginTop: 16 }}>
                {folders.length == 0 ? (
                  <Grid
                    container
                    item
                    marginTop={8}
                    marginBottom={8}
                    justifyContent={"center"}
                  >
                    <Typography style={{ color: "grey" }}>
                      Nothing here yet
                    </Typography>
                  </Grid>
                ) : (
                  <List>
                    {folders.map((folder, index) => (
                      <>
                        <ListItem
                          disableRipple={showingOptions != null}
                          disabled={
                            showingOptions &&
                            !(
                              showingOptions?.id == folder.id &&
                              showingOptions?.name == folder.name
                            )
                          }
                          selected={
                            showingOptions &&
                            showingOptions?.id == folder.id &&
                            showingOptions?.name == folder.name
                          }
                          key={folder.id}
                          // onClick={(e) => {
                          //   if (showingOptions) return;
                          //   if (
                          //     e.target.tagName.toLowerCase() == "p" ||
                          //     e.target.tagName.toLowerCase() == "span" ||
                          //     e.target.tagName.toLowerCase() == "div"
                          //   )
                          //     setCurrentFolder(folder.id, folder.name);
                          // }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              style={{ backgroundColor: generateRandomColor() }}
                            >
                              {folder.name[0].toUpperCase()}
                            </Avatar>
                          </ListItemAvatar>
                          {/* <ListItemIcon>
                  <Folder />
                </ListItemIcon> */}
                          <ListItemText
                            primary={folder.name}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {folder?.Client?.email}
                                </Typography>
                                {` - ${moment(folder.created_at).format(
                                  "YYYY-MM-DD"
                                )}`}
                              </React.Fragment>
                            }
                          />
                          {user?.role == "A" && (
                            <ListItemSecondaryAction>
                              <ActionMenuButton
                                onOpen={() => {
                                  setShowingOptions(folder);
                                }}
                                handleEdit={(newName, newEmail, newPhone) => {
                                  rename({
                                    id: folder.id,
                                    type: "folders",
                                    newName,
                                    newEmail,
                                    newPhone,
                                  });
                                }}
                                handleDelete={() =>
                                  deleteItem({
                                    id: folder.id,
                                    email: folder?.Client?.email,
                                    type: "folders",
                                  })
                                }
                                onClose={() => {
                                  setShowingOptions(false);
                                }}
                                fileName={folder.name}
                                emailAccount={folder?.Client?.email}
                                phoneNum={folder?.Client?.phone}
                              />
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>

                        {index + 1 < folders.length && (
                          <Divider variant="inset" light />
                        )}
                      </>
                    ))}
                  </List>
                )}
              </Paper>
            </>
          ) : (
            <>
              {user?.role === "A" && (
                <Paper variant="outlined" style={{ marginTop: 16 }}>
                  {/* Create Folder */}
                  {user?.role === "A" && (
                    <>
                      <ListItemButton
                        color="primary"
                        button
                        onClick={() => {
                          setOpenCreateFolderDialog(true);
                          setGenericFolderId(true);
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 32,
                        }}
                      >
                        <ListItemIcon color="primary">
                          <CreateNewFolderIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText color="primary" primary="Create Folder" />
                      </ListItemButton>
                      <Divider variant="inset" light />
                    </>
                  )}
                  {genericFolderData.length == 0 ? (
                    <Grid
                      container
                      item
                      marginTop={8}
                      marginBottom={8}
                      justifyContent={"center"}
                    >
                      <Typography style={{ color: "grey" }}>
                        Nothing here yet
                      </Typography>
                    </Grid>
                  ) : (
                    <List>
                      {genericFolderData?.map &&
                        genericFolderData?.map((file, index) => (
                          <>
                            <ListItemButton
                              disableRipple={showingOptions != null}
                              disabled={
                                showingOptions &&
                                !(
                                  showingOptions?.id == file.id &&
                                  showingOptions?.name == file.name
                                )
                              }
                              selected={
                                showingOptions &&
                                showingOptions?.id == file.id &&
                                showingOptions?.name == file.name
                              }
                              key={file.id + "_folder"}
                              onClick={(e) => {
                                if (showingOptions) return;
                                if (
                                  e.target.tagName.toLowerCase() == "span" ||
                                  e.target.tagName.toLowerCase() == "p" ||
                                  e.target.tagName.toLowerCase() == "div"
                                )
                                  setCurrentFolder(file.id, file.name);
                              }}
                            >
                              <ListItemIcon>
                                <Folder />
                              </ListItemIcon>
                              <ListItemText
                                primary={file.name}
                                secondary={moment(file.created_at).format(
                                  "YYYY-MM-DD"
                                )}
                              />
                              {(!showingOptions ||
                                (showingOptions?.id == file.id &&
                                  showingOptions?.name == file.name)) &&
                                user?.role == "A" && (
                                  <ListItemSecondaryAction>
                                    <ActionMenuButton
                                      onOpen={() => {
                                        setShowingOptions(file);
                                      }}
                                      handleEdit={(newName) => {
                                        rename({
                                          id: file.id,
                                          type: "folders",
                                          newName,
                                          generic: "true",
                                        });
                                      }}
                                      handleDelete={() =>
                                        deleteItem({
                                          id: file.id,
                                          type: "folders",
                                          generic: "true",
                                        })
                                      }
                                      onClose={() => {
                                        setShowingOptions(false);
                                      }}
                                      fileName={file.name}
                                      assignUserLoading={assignUserLoading}
                                      genericId={(folderId, userId) =>
                                        handleAssignUser({
                                          folder_id: folderId,
                                          user_id: userId,
                                        })
                                      }
                                      folder_id={file?.id}
                                    />
                                  </ListItemSecondaryAction>
                                )}
                            </ListItemButton>

                            {index + 1 < genericFolderData.length && (
                              <Divider variant="inset" light />
                            )}
                          </>
                        ))}
                    </List>
                  )}
                </Paper>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="button-container"
          >
            <Box display="flex" alignItems="center">
              {(breadCrumbs?.length > 1 || user?.role == "A") && (
                <Button
                  startIcon={<ArrowBackIcon />}
                  edge="start"
                  color="primary"
                  variant="contained"
                  style={{ padding: "2px 8px", marginRight: 8, color: "white" }}
                  onClick={() => {
                    goBack();
                  }}
                >
                  Back
                </Button>
              )}
            </Box>

            {/* {user?.role === "A" && ( */}
            <Button
              startIcon={<DescriptionIcon />}
              style={{ color: "white" }}
              variant="contained"
              color="primary"
              onClick={handleAddFileDialogOpen}
              className="file_btn"
            >
              Add file and folder
            </Button>
            {/* )} */}
          </Box>
          <Box
            style={{ marginTop: 16 }}
            display="flex"
            alignItems="center"
            justifyContent="start"
            className="button-container"
          >
            {breadCrumbs.map((crumb, index) => {
              return breadCrumbs.length > 1 &&
                index < breadCrumbs.length - 1 ? (
                <>
                  <Typography
                    style={{ color: "grey", fontSize: 12 }}
                    variant="h6"
                  >
                    {crumb.name}
                  </Typography>
                  <ArrowForwardIcon
                    style={{ marginLeft: 4, marginRight: 4, fontSize: 12 }}
                  />
                </>
              ) : (
                <Typography
                  style={{
                    fontSize: 13,
                    borderBottom: "1.5px solid rgb(76 175 80)",

                    //intend border
                  }}
                  variant="h6"
                >
                  {crumb.name}
                </Typography>
              );
            })}
          </Box>

          <Paper variant="outlined" style={{ marginTop: 16, marginBottom: 16 }}>
            {user?.role === "A" && (
              <>
                <ListItemButton
                  color="primary"
                  button
                  onClick={() => setOpenCreateFolderDialog(true)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 32,
                  }}
                >
                  <ListItemIcon color="primary">
                    <CreateNewFolderIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText color="primary" primary="Create Folder" />
                </ListItemButton>
                <Divider variant="inset" light />
              </>
            )}
            {existFile.length === 0 ? (
              <Grid
                container
                item
                marginTop={8}
                marginBottom={8}
                justifyContent={"center"}
              >
                <Typography style={{ color: "grey" }}>
                  Nothing here yet
                </Typography>
              </Grid>
            ) : (
              <>
                {breadCrumbs?.length <= 1 && user?.role === "C" ? (
                  <>
                    <List style={{ paddingBottom: "0" }}>
                      {genericFolderData?.map &&
                        genericFolderData?.map((file, index) => (
                          <>
                            <ListItemButton
                              disableRipple={showingOptions != null}
                              disabled={
                                showingOptions &&
                                !(
                                  showingOptions?.id == file.id &&
                                  showingOptions?.name == file.name
                                )
                              }
                              selected={
                                showingOptions &&
                                showingOptions?.id == file.id &&
                                showingOptions?.name == file.name
                              }
                              key={file.id + "_folder"}
                              onClick={(e) => {
                                if (showingOptions) return;
                                if (
                                  e.target.tagName.toLowerCase() == "span" ||
                                  e.target.tagName.toLowerCase() == "p" ||
                                  e.target.tagName.toLowerCase() == "div"
                                )
                                  setCurrentFolder(file.id, file.name);
                              }}
                            >
                              <ListItemIcon>
                                <Folder />
                              </ListItemIcon>
                              <ListItemText
                                primary={file.name}
                                secondary={moment(file.created_at).format(
                                  "YYYY-MM-DD"
                                )}
                              />
                              {(!showingOptions ||
                                (showingOptions?.id == file.id &&
                                  showingOptions?.name == file.name)) &&
                                user?.role == "A" && (
                                  <ListItemSecondaryAction>
                                    <ActionMenuButton
                                      onOpen={() => {
                                        setShowingOptions(file);
                                      }}
                                      handleEdit={(newName) => {
                                        rename({
                                          id: file.id,
                                          type: "folders",
                                          newName,
                                        });
                                      }}
                                      handleDelete={() =>
                                        deleteItem({
                                          id: file.id,
                                          type: "folders",
                                        })
                                      }
                                      onClose={() => {
                                        setShowingOptions(false);
                                      }}
                                      fileName={file.name}
                                      assignUserLoading={assignUserLoading}
                                      genericId={(folderId, userId) =>
                                        handleAssignUser({
                                          folder_id: folderId,
                                          user_id: userId,
                                        })
                                      }
                                      folder_id={file?.id}
                                    />
                                  </ListItemSecondaryAction>
                                )}
                            </ListItemButton>

                            {index + 1 < genericFolderData.length && (
                              <Divider variant="inset" light />
                            )}
                          </>
                        ))}
                    </List>
                    {/* )} */}
                    {files?.length !== 0 && <Divider variant="inset" light />}
                    <List style={{ paddingTop: "0" }}>
                      {files?.map &&
                        files?.map((file, index) => (
                          <>
                            {file.filePath ? (
                              <ListItem
                                key={file.id}
                                disableRipple={showingOptions != null}
                                disabled={
                                  showingOptions &&
                                  !(
                                    showingOptions?.id == file.id &&
                                    showingOptions?.name == file.name
                                  )
                                }
                                selected={
                                  showingOptions &&
                                  showingOptions?.id == file.id &&
                                  showingOptions?.name == file.name
                                }
                              >
                                <ListItemIcon>
                                  <DescriptionIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Button
                                      sx={{ padding: 0 }}
                                      disabled={file.id == downloadingFileID}
                                      onClick={() =>
                                        downloadFile(
                                          file.id,
                                          file.name,
                                          file.filePath
                                        )
                                      }
                                      variant="text"
                                    >
                                      {file.name}
                                    </Button>
                                  }
                                  secondary={moment(file.uploaded_at).format(
                                    "YYYY-MM-DD"
                                  )}
                                />

                                <ListItemSecondaryAction
                                  style={{ display: "flex" }}
                                >
                                  {file.id == downloadingFileID ? (
                                    <CircularProgress />
                                  ) : showingOptions ? (
                                    <></>
                                  ) : (
                                    <IconButton
                                      color="primary"
                                      variant="outlined"
                                      onClick={() =>
                                        downloadFile(
                                          file.id,
                                          file.name,
                                          file.filePath
                                        )
                                      }
                                    >
                                      <Download />
                                    </IconButton>
                                  )}
                                  {(!showingOptions ||
                                    (showingOptions?.id == file.id &&
                                      showingOptions?.name == file.name)) &&
                                    user?.role == "A" && (
                                      <ActionMenuButton
                                        onOpen={() => {
                                          setShowingOptions(file);
                                        }}
                                        handleEdit={(
                                          newName,
                                          newEmail,
                                          newPhone
                                        ) => {
                                          rename({
                                            id: file.id,
                                            type: "files",
                                            oldName: file.name,
                                            newName,
                                            newEmail,
                                            newPhone,
                                          });
                                        }}
                                        handleDelete={() =>
                                          deleteItem({
                                            id: file.id,
                                            type: "files",
                                          })
                                        }
                                        onClose={() => {
                                          setShowingOptions(false);
                                        }}
                                        fileName={file.name.substring(
                                          0,
                                          file.name.lastIndexOf(".")
                                        )}
                                      />
                                    )}
                                </ListItemSecondaryAction>
                              </ListItem>
                            ) : (
                              <ListItemButton
                                disableRipple={showingOptions != null}
                                disabled={
                                  showingOptions &&
                                  !(
                                    showingOptions?.id == file.id &&
                                    showingOptions?.name == file.name
                                  )
                                }
                                selected={
                                  showingOptions &&
                                  showingOptions?.id == file.id &&
                                  showingOptions?.name == file.name
                                }
                                key={file.id + "_folder"}
                                onClick={(e) => {
                                  if (showingOptions) return;
                                  if (
                                    e.target.tagName.toLowerCase() == "span" ||
                                    e.target.tagName.toLowerCase() == "p" ||
                                    e.target.tagName.toLowerCase() == "div"
                                  )
                                    setCurrentFolder(file.id, file.name);
                                }}
                              >
                                <ListItemIcon>
                                  <Folder />
                                </ListItemIcon>
                                <ListItemText
                                  primary={file.name}
                                  secondary={moment(file.created_at).format(
                                    "YYYY-MM-DD"
                                  )}
                                />
                                {(!showingOptions ||
                                  (showingOptions?.id == file.id &&
                                    showingOptions?.name == file.name)) &&
                                  user?.role == "A" && (
                                    <ListItemSecondaryAction>
                                      <ActionMenuButton
                                        onOpen={() => {
                                          setShowingOptions(file);
                                        }}
                                        handleEdit={(newName) => {
                                          rename({
                                            id: file.id,
                                            type: "folders",
                                            newName,
                                          });
                                        }}
                                        handleDelete={() =>
                                          deleteItem({
                                            id: file.id,
                                            type: "folders",
                                          })
                                        }
                                        onClose={() => {
                                          setShowingOptions(false);
                                        }}
                                        fileName={file.name}
                                      />
                                    </ListItemSecondaryAction>
                                  )}
                              </ListItemButton>
                            )}
                            {index + 1 < files.length && (
                              <Divider variant="inset" light />
                            )}
                          </>
                        ))}
                    </List>
                  </>
                ) : (
                  <List>
                    {files.length == 0 ? (
                      <Grid
                        container
                        item
                        marginTop={8}
                        marginBottom={8}
                        justifyContent={"center"}
                      >
                        <Typography style={{ color: "grey" }}>
                          Nothing here yet
                        </Typography>
                      </Grid>
                    ) : (
                      <List>
                        {files?.map &&
                          files?.map((file, index) => (
                            <>
                              {file.filePath ? (
                                <ListItem
                                  key={file.id}
                                  disableRipple={showingOptions != null}
                                  disabled={
                                    showingOptions &&
                                    !(
                                      showingOptions?.id == file.id &&
                                      showingOptions?.name == file.name
                                    )
                                  }
                                  selected={
                                    showingOptions &&
                                    showingOptions?.id == file.id &&
                                    showingOptions?.name == file.name
                                  }
                                >
                                  <ListItemIcon>
                                    <DescriptionIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Button
                                        sx={{ padding: 0 }}
                                        disabled={file.id == downloadingFileID}
                                        onClick={() =>
                                          downloadFile(
                                            file.id,
                                            file.name,
                                            file.filePath
                                          )
                                        }
                                        variant="text"
                                      >
                                        {file.name}
                                      </Button>
                                    }
                                    secondary={moment(file.uploaded_at).format(
                                      "YYYY-MM-DD"
                                    )}
                                  />

                                  <ListItemSecondaryAction
                                    style={{ display: "flex" }}
                                  >
                                    {file.id == downloadingFileID ? (
                                      <CircularProgress />
                                    ) : showingOptions ? (
                                      <></>
                                    ) : (
                                      <IconButton
                                        color="primary"
                                        variant="outlined"
                                        onClick={() =>
                                          downloadFile(
                                            file.id,
                                            file.name,
                                            file.filePath
                                          )
                                        }
                                      >
                                        <Download />
                                      </IconButton>
                                    )}
                                    {(!showingOptions ||
                                      (showingOptions?.id == file.id &&
                                        showingOptions?.name == file.name)) &&
                                      user?.role == "A" && (
                                        <ActionMenuButton
                                          onOpen={() => {
                                            setShowingOptions(file);
                                          }}
                                          handleEdit={(
                                            newName,
                                            newEmail,
                                            newPhone
                                          ) => {
                                            rename({
                                              id: file.id,
                                              type: "files",
                                              oldName: file.name,
                                              newName,
                                              newEmail,
                                              newPhone,
                                            });
                                          }}
                                          handleDelete={() =>
                                            deleteItem({
                                              id: file.id,
                                              type: "files",
                                            })
                                          }
                                          onClose={() => {
                                            setShowingOptions(false);
                                          }}
                                          fileName={file.name.substring(
                                            0,
                                            file.name.lastIndexOf(".")
                                          )}
                                        />
                                      )}
                                  </ListItemSecondaryAction>
                                </ListItem>
                              ) : (
                                <ListItemButton
                                  disableRipple={showingOptions != null}
                                  disabled={
                                    showingOptions &&
                                    !(
                                      showingOptions?.id == file.id &&
                                      showingOptions?.name == file.name
                                    )
                                  }
                                  selected={
                                    showingOptions &&
                                    showingOptions?.id == file.id &&
                                    showingOptions?.name == file.name
                                  }
                                  key={file.id + "_folder"}
                                  onClick={(e) => {
                                    if (showingOptions) return;
                                    if (
                                      e.target.tagName.toLowerCase() ==
                                        "span" ||
                                      e.target.tagName.toLowerCase() == "p" ||
                                      e.target.tagName.toLowerCase() == "div"
                                    )
                                      setCurrentFolder(file.id, file.name);
                                  }}
                                >
                                  <ListItemIcon>
                                    <Folder />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={file.name}
                                    secondary={moment(file.created_at).format(
                                      "YYYY-MM-DD"
                                    )}
                                  />
                                  {(!showingOptions ||
                                    (showingOptions?.id == file.id &&
                                      showingOptions?.name == file.name)) &&
                                    user?.role == "A" && (
                                      <ListItemSecondaryAction>
                                        <ActionMenuButton
                                          onOpen={() => {
                                            setShowingOptions(file);
                                          }}
                                          handleEdit={(newName) => {
                                            rename({
                                              id: file.id,
                                              type: "folders",
                                              newName,
                                            });
                                          }}
                                          handleDelete={() =>
                                            deleteItem({
                                              id: file.id,
                                              type: "folders",
                                            })
                                          }
                                          onClose={() => {
                                            setShowingOptions(false);
                                          }}
                                          fileName={file.name}
                                        />
                                      </ListItemSecondaryAction>
                                    )}
                                </ListItemButton>
                              )}
                              {index + 1 < files.length && (
                                <Divider variant="inset" light />
                              )}
                            </>
                          ))}
                      </List>
                    )}
                  </List>
                )}
              </>
            )}
          </Paper>
        </>
      )}

      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={openAddFileDialog}
        onClose={handleAddFileDialogClose}
      >
        <DialogTitle>Add File or Folder</DialogTitle>
        <DialogContent>
          {/* {/ Drag and Drop Area /} */}
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #ccc",
              borderRadius: "4px",
              padding: "16px",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: isDragActive ? "#f0f8ff" : "white", // Highlight when dragging
              borderColor: isDragActive ? "#00f" : "#ccc", // Change border color when dragging
            }}
            onClick={open}
          >
            <input {...getInputProps()} />
            <Typography>
              Drag & drop files and folders here, or click to select files or
              folders.
            </Typography>
          </Box>
          {selectedFiles && selectedFiles.length > 0 && (
            <Box mt={2}>
              {selectedFiles &&
                selectedFiles.map((file) => (
                  <Typography key={file.name}>{file.name}</Typography>
                ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddFileDialogClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onUpload}
            style={{ color: "white" }}
            disabled={selectedFiles?.length === 0 || uploading}
          >
            {uploading ? <CircularProgress size={24} /> : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={openCreateFolderDialog}
        onClose={handleCreateFolderDialogClose}
      >
        <DialogTitle>
          Create{" "}
          {!currentFolderId
            ? genericFolderId
              ? "Folder"
              : "Investor"
            : "Folder"}
        </DialogTitle>
        <DialogContent>
          {/* Create Folder Dialog Content */}
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel htmlFor="folder-name">
              {!currentFolderId
                ? genericFolderId
                  ? "Folder"
                  : "Investor"
                : "Folder"}{" "}
              Name
            </InputLabel>
            <Input
              id="folder-name"
              type="text"
              value={newFolderName}
              onChange={handleFolderNameChange}
            />
          </FormControl>
          {!currentFolderId ? (
            genericFolderId ? (
              ""
            ) : (
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel htmlFor="folder-email">Email</InputLabel>
                <Input
                  id="folder-email"
                  type="email"
                  value={newFolderEmail}
                  onChange={handleNewFolderEmailChange}
                />
              </FormControl>
            )
          ) : (
            ""
          )}
          {!currentFolderId ? (
            genericFolderId ? (
              ""
            ) : (
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel htmlFor="folder-phone">Phone</InputLabel>
                <Input
                  id="folder-phone"
                  value={newFolderPhone}
                  onChange={handleNewFolderPhoneChange}
                />
              </FormControl>
            )
          ) : (
            ""
          )}
          {error && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateFolderDialogClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            style={{ color: creatingFolder ? "grey" : "white" }}
            onClick={createFolder}
            disabled={
              !newFolderName ||
              creatingFolder ||
              (!newFolderEmail &&
                !newFolderPhone &&
                !currentFolderId &&
                !genericFolderId)
            }
          >
            {creatingFolder
              ? "Creating..."
              : `Create ${
                  !currentFolderId
                    ? genericFolderId
                      ? "Folder"
                      : "Investor"
                    : "Folder"
                }`}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Dashboard;
